<template>
  <div>
    <h1>404 - Page Not Found</h1>
    <p>The page you are looking for does not exist.</p>
    <button @click="goHome">Go to Home Page</button>
  </div>
</template>

<script>
export default {
  methods: {
    goHome() {
      this.$router.push({ name: 'HomePage' });
    },
  },
};
</script>

<style scoped>
h1 {
  color: #e74c3c;
  text-align: center;
}
p {
  text-align: center;
  font-size: 16px;
  margin: 10px 0;
}
button {
  display: block;
  margin: 20px auto;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
}
</style>
