import axios from 'axios';

const apiClient = axios.create({
  baseURL: process.env.VUE_APP_API_BASE_URL, // 後端服務的基本 URL
  headers: {
    'Content-Type': 'application/json',
  },
});

// 請求 /api/t1 的資料
export function fetchT1Data() {
  return apiClient.get('/api/t1')
    .then(response => response.data)
    .catch(error => {
      console.error("Error fetching T1 data:", error);
      throw error;
    });
}

// 請求 /api/w1 的資料
export function fetchW1Data() {
  return apiClient.get('/api/w1')
    .then(response => response.data)
    .catch(error => {
      console.error("Error fetching W1 data:", error);
      throw error;
    });
}

export function fetchS1Data() {
  return apiClient.get('/api/s1')
    .then(response => response.data)
    .catch(error => {
      console.error("Error saving data:", error);
      throw error;
    });
}
export function saveData(name) {
    return apiClient.post('/api/s1', name)
      .then(response => response.data)
      .catch(error => {
        console.error("Error saving data:", error);
        throw error;
      });
  }
  